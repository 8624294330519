import React from 'react'
import { Link } from 'react-router-dom'
import HeroCarousel from '../components/HeroCarousel'
import VIP from '../components/VIP'
import Chancellors from '../components/vips.json'
import AboutUsSection from '../components/AboutSection'
import LatestNewsMarquee from './LatestNewsMarquee'
import EventsSection from './EventsSection'
import QuickButton from '../components/QuickButton'
import Facilities from '../components/Facilities'
import CTA from '../components/CTA'
import GallerySlide from '../components/GallerySlide'
import { useSelector } from 'react-redux'

function Home() {
  const store = useSelector((state) => state.auth.store);
  const quicklinks=[
    {
      'name':'Exams',
      'image':'https://university.acetians.in/uploads/icon1.png',
      
    },
    {
      'name':'Admissions',
      'image':'https://university.acetians.in/uploads/icon2.png',
      
    },
    {
      'name':'Results',
      'image':'https://university.acetians.in/uploads/icon3.png',
      
    },
    {
      'name':'Students',
      'image':'https://university.acetians.in/uploads/icon4.png',
      
    },
    {
      'name':'University',
      'image':'https://university.acetians.in/uploads/icon5.png',
      
    },
    {
      'name':'Academics',
      'image':'https://university.acetians.in/uploads/icon6.png',
      
    }
  ] 
  const events = [
    {
      title: "पाँच दिवसीय रोवर्स-रेंजर्स प्रशिक्षण एवं जांच शिविर काआयोजन",
      link: "https://vbsgdc.org.in/uploads/2023/11/IMG-20240306-WA0027-e1710227497643.jpg",
      image: "https://vbsgdc.org.in/uploads/2023/11/IMG-20231125-WA0001.jpg",
      date: "March 11, 2024",
    },
    {
      title: "राष्ट्रीय सेवा योजना के अंतर्गत सप्तदिवसीय विशेष शिविर संबंधित सूचना",
      link: "https://vbsgdc.org.in/uploads/2024/02/NSS-VISHESH-SHIVIR-SUCHANA.pdf",
      image: "https://vbsgdc.org.in/uploads/2023/11/IMG-20231125-WA0008.jpg",
      date: "February 19, 2024",
    },
  ];

  const nextEvents = [
    {
      title: "पाँच दिवसीय रोवर्स-रेंजर्स प्रशिक्षण एवं जांच शिविर काआयोजन",
      link: "https://vbsgdc.org.in/uploads/2023/11/IMG-20240306-WA0027-e1710227497643.jpg",
      date: "March 11, 2024",
    },
    {
      title: "राष्ट्रीय सेवा योजना के अंतर्गत सप्तदिवसीय विशेष शिविर संबंधित सूचना",
      link: "https://vbsgdc.org.in/uploads/2024/02/NSS-VISHESH-SHIVIR-SUCHANA.pdf",
      date: "February 19, 2024",
    },
    {
      title: "Job Fair from Regional Employment Office Gorakhpur",
      link: "https://vbsgdc.org.in/uploads/2023/11/Rozgar-Mela-281123.docx",
      date: "April 22, 2023",
    },
  ];
  return (
    <div>
      <div className='d-flex w-100' style={{maxWidth:'100vw'}}>
         <div className='col-md-9 p-0' style={{padding:0}}>
         <HeroCarousel/>
         </div>
         <div className='col-md-3'>
          <ul style={{padding:'0px',margin:'0px'}}>
            {Chancellors.map((chancellor,index)=>(<VIP key={index} chancellor={chancellor}/>))}
          </ul>
         </div>
      </div>
    <LatestNewsMarquee newsItems={[{
      title: "List of Selected Students with topics for Summer Internship Project",
      link: "downloadmedia/UploadNews/pdf/C_202407101602056301.pdf",
      size: "File Size: 370 KB",
      language: "English",
      date: "05/07/2024",
      isLocal: false,
    },
    {
      title: "Application invited for extension of Affiliation for 2024-25",
      link: "downloadmedia/UploadNews/pdf/C_202406251139326301.pdf",
      size: "File Size: 431 KB",
      language: "Hindi",
      date: "25/06/2024",
      isLocal: false,
    },]}/>

    <ul id="quicklinks-ul">
      {quicklinks.map((link,index)=>(<QuickButton key={index} link={link}/>))}
    </ul>
    
    <AboutUsSection
      title="Welcome to"
      subtitle="VBS Govt. Degree College"
      description="वीर बहादुर सिंह राजकीय महाविद्यालय कैंपियरगंज गोरखपुर, गोरखपुर रेलवे स्टेशन से 35 किलोमीटर दूर गोरखपुर सोनौली रोड पर स्थित है इस महाविद्यालय में कला संकाय, विज्ञान संकाय एवं वाणिज्य संकाय संचालित है यह महाविद्यालय दीनदयाल उपाध्याय गोरखपुर विश्वविद्यालय से संबद्ध एवं उत्तर प्रदेश शासन से संचालित है"
      eContentLink="/e-pathshalas/"
      feedbackLink="https://vbsgdc.org.in/feedback-form/"
      imageUrl="https://vbsgdc.org.in/uploads/2020/01/vg1.jpeg"
    /> 
    
    <EventsSection events={events} nextEvents={nextEvents}/>
    <AboutUsSection
      title="PRINCIPAL'S"
      subtitle="MESSAGE"
      description={`Principal’s Message :${store.principal_message}`}
      eContentLink=""
      feedbackLink=""
      imageUrl={`https://campus.acetians.in/custom/images/${store.principal_image}`}
    />
    <Facilities/>
    <CTA/>
    <GallerySlide/>
    </div>
  )
}

export default Home
