import React from 'react'
import PageHeader from '../components/PageHeader'
import PageContent from '../components/PageContent'
import TableComponent from '../components/TableComponent';

function RankingNirf() {
    const thead = ['S.No','Topic', 'File'];
const tbody = [
    [
        1,
        "College Level Data Submitted for NIRF Rankings 2024",
        "https://vbsgdc.org.in/uploads/2024/03/College-Level-Veer-Bahadur-Singh-Rajkeeya-Mahavidyalay-kampiyarganj-Gorakhpur20240117-.pdf"
      ],
      [
        2,
        "Overall Data Submitted for NIRF Rankings 2024",
        "https://vbsgdc.org.in/uploads/2024/03/Overall-Level-Veer-Bahadur-Singh-Rajkeeya-Mahavidyalay-kampiyarganj-Gorakhpur20240119-.pdf"
      ]
];


  return (
    <div> 
      <PageHeader title="NIRF" />
      <PageContent elem={<TableComponent thead={thead} tbody={tbody} />}/>

    </div>
  )
}

export default RankingNirf
